
    import { Component, Vue } from "vue-property-decorator";
    import RoutesEnum from "@/router/routesEnum";

@Component
    export default class BannerHeader extends Vue
    {
        onClickLogo()
        {
            if (this.$route.name !== RoutesEnum.Package)
            {
                this.$router.push({ name: RoutesEnum.Package });
            }
        }
    }
